const menu = $('.js-menu')
const body = $('body')

$('.js-openMenu').on('click', (event) => {
  const elem = $(event.currentTarget)

  elem.toggleClass('is_active')
  menu.toggleClass('d-n')
  body.toggleClass('o-h')
})
