import Swiper, { Navigation, Pagination } from 'swiper'

if ($('.js-sliderBase').length > 0) {
  $('.js-sliderBase').each((index, elem) => {
    const sliderElem = $(elem)
    const hasPagination = sliderElem.find('.swiper-pagination').length > 0

    sliderElem.addClass(`js-sliderBase-${index}`)
    sliderElem.find('.swiper-next').addClass(`js-sliderBaseNext-${index}`)
    sliderElem.find('.swiper-prev').addClass(`js-sliderBasePrev-${index}`)

    if (hasPagination) {
      sliderElem.find('.swiper-pagination').addClass(`js-sliderBasePagination-${index}`)
    }

    const paginationConfig = {
      el: `.js-sliderBasePagination-${index}`,
      clickable: true,
    }

    new Swiper(`.js-sliderBase-${index} .swiper`, {
      modules: [Navigation, Pagination],
      slidesPerView: 'auto',
      pagination: hasPagination ? paginationConfig : false,
      navigation: {
        nextEl: `.js-sliderBaseNext-${index}`,
        prevEl: `.js-sliderBasePrev-${index}`,
      },
    })
  })
}
