import SimpleBar from 'simplebar'

window.scrollInit = () => {
  $('.js-scroll').each((index, elem) => {
    const item = $(elem)
    const scrollContent = item.find('.js-scrollContent').outerHeight()

    if (scrollContent > 162) {
      new SimpleBar(elem, { autoHide: false })
    }
  })

  $('body').addClass('scroll-init')
}

window.scrollInit()
