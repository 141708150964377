const formList = document.querySelectorAll('.js-validate')

const defaultConfig = {
  classTo: 'form-group',
  errorClass: 'is-error',
  successClass: 'is-success',
  errorTextParent: 'form-group',
}

formList.forEach((form) => {
  const pristine = new Pristine(form, defaultConfig)

  form.addEventListener('submit', (event) => {
    const valid = pristine.validate()

    if (!valid) {
      event.preventDefault()
    }
  })

  const phoneElem = form.querySelector('.js-phone')

  if (!!phoneElem === true) {
    pristine.addValidator(
      phoneElem,
      (value) => value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$/),
      'Указан неверный формат телефона',
    )
  }

  const passwordElem = form.querySelector('.js-password')

  if (!!passwordElem === true) {
    pristine.addValidator(
      passwordElem,
      (value) => {
        const stateLength = value.length > 5
        const stateUppercase = value.match(/[A-ZА-ЯЁ]/g)
        const stateLowercase = value.match(/[a-zа-яё]/g)

        return stateLength && stateUppercase && stateLowercase
      },
      'Пароль должен содержать минимум 6 символов, минимум одну строчную и прописную буквы',
    )
  }
})
