$('.js-togglePassword').on('click', (event) => {
  const elem = $(event.currentTarget)
  const parent = elem.parents('.js-parentPassword')
  const input = parent.find('input')

  if (input.attr('type') === 'password') {
    input.attr('type', 'text')
    elem.addClass('is_select')
  } else {
    input.attr('type', 'password')
    elem.removeClass('is_select')
  }
})
