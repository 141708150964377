function parseValue(val) {
  if (val.length < 1) {
    val = 1
  }

  return parseInt(val)
}

$(document).on('change', '.js-counter input', (event) => {
  if (event.currentTarget.value.match(/[^0-9]/g)) {
    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '')
  }

  event.currentTarget.value = parseValue(event.currentTarget.value)
})

$(document).on('click', '.js-counterPlus', (event) => {
  const elem = $(event.currentTarget)
  const input = elem.parents('.js-counter').find('input')

  input.val(+input.val() + 1)
})

$(document).on('click', '.js-counterMinus', (event) => {
  const elem = $(event.currentTarget)
  const input = elem.parents('.js-counter').find('input')

  if (+input.val() > 1) {
    input.val(+input.val() - 1)
  }
})
