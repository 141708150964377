$('.js-toggleSlide').on('click', (event) => {
  const elem = $(event.currentTarget)

  elem.toggleClass('is_active')
  elem.next().slideToggle()
})

$('.js-toggleShow').on('click', (event) => {
  const elem = $(event.currentTarget)

  elem.toggleClass('is_active')
  elem.next().toggleClass('is_show')
})
