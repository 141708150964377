const searchBlock = $('.js-searchBlock')

function openSearch() {
  searchBlock.removeClass('d-m-n')

  searchBlock.find('input').focus()
}

function closeSearch() {
  searchBlock.addClass('d-m-n')
}

if (searchBlock.length > 0) {
  $('.js-openSearch').on('click', openSearch)
  $('.js-closeSearch').on('click', closeSearch)
}
