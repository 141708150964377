$('.js-toggleChangeForm').on('click', (event) => {
  const elem = $(event.currentTarget)
  const form = elem.parents('form')

  elem.toggleClass('is_toggle')

  if (elem.hasClass('is_toggle')) {
    form
      .find('input, textarea, .personal-form__submit button')
      .removeAttr('disabled')
  } else {
    form
      .find('input, textarea, .personal-form__submit button')
      .attr('disabled', 'disabled')
  }
})
