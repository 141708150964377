import magnificPopup from 'magnific-popup'

function modalOpen(src, type) {
  $.magnificPopup.open({
    items: {
      src: src,
      type: type,
    },
    showCloseBtn: false,
    closeBtnInside: true,
    callbacks: {
      // ajaxContentAdded: function () {
      //   sliderGalleryInit()
      // },
      beforeOpen: () => {
        $('html').addClass('mfp-open')
      },

      beforeClose: () => {
        $('html').removeClass('mfp-open')
      },
    },
  })
}

$(document).on('click', '.js-modalLink', (event) => {
  event.preventDefault()

  const context = $(event.currentTarget)
  const src = context.data('mfp-src')
  const type = context.data('mfp-ajax') || 'inline'

  modalOpen(src, type)
})

$(document).on('click', '.js-modalClose', (event) => {
  event.preventDefault()
  $.magnificPopup.close()
})
