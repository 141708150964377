if ($('.js-rangeSlider').length > 0) {
  $('.js-rangeSlider').ionRangeSlider({
    type: 'double',
    hide_min_max: true,
    force_edges: true,
    hide_from_to: true,
  })

  $('.js-rangeSlider').on('change', (event) => {
    const elem = $(event.currentTarget)
    const parent = elem.parents('.js-rangeParent')
    const currentValue = elem.val().split(';')
    
    const valueFrom = currentValue[0]
    const valueTo = currentValue[1]
    
    const rangeInputFrom = parent.find('.js-rangeInputFrom input')
    const rangeInputTo = parent.find('.js-rangeInputTo input')

    rangeInputFrom.val(valueFrom)
    rangeInputTo.val(valueTo)
  })
}
