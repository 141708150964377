const filterBlock = $('.js-filterBlock')
const body = $('body')

$('.js-openFilterMobile').on('click', () => {
  filterBlock.addClass('is_show')
  body.addClass('o-h')
})

$('.js-closeFilterMobile').on('click', () => {
  filterBlock.removeClass('is_show')
  body.removeClass('o-h')
})
