const catalog = $('.js-catalog')
const body = $('body')
const menu = $('.js-menu')
const openMenu = $('.js-openMenu')
const catalogSubmenuTitle = $('.js-catalogSubmenuTitle')

function openCatalog() {
  catalog.removeClass('d-n')
  body.addClass('o-h')

  document.addEventListener('keydown', keyPressEscape)
}

function closeCatalog() {
  catalog.addClass('d-n')
  body.removeClass('o-h')

  if (window.innerWidth < 768) {
    menu.addClass('d-n')
    body.removeClass('o-h')
    openMenu.removeClass('is_active')
    catalog.removeClass('is_submenu')

    closeSubmenu()
  }

  document.removeEventListener('keydown', keyPressEscape)
}

function closeSubmenu() {
  catalog.removeClass('is_submenu')

  $('.catalog-elem').show().removeClass('is_show')
}

function backCatalog() {
  if (catalog.hasClass('is_submenu')) {
    closeSubmenu()

    return
  }

  catalog.addClass('d-n')
}

function openSubmenu(event) {
  const elem = $(event.currentTarget)
  const title = elem.find('.catalog-card__title').text()
  const parentElem = elem.parents('.catalog-elem')

  $('.catalog-elem').hide().removeClass('is_show')
  parentElem.show().addClass('is_show')

  catalog.addClass('is_submenu')
  catalogSubmenuTitle.text(title)
}

function keyPressEscape(event) {
  if (event.key === 'Escape') {
    closeCatalog()
  }
}

$('.js-openCatalog').on('click', openCatalog)
$('.js-closeCatalog').on('click', closeCatalog)
$('.js-backCatalog').on('click', backCatalog)
$('.js-openSubmenu').on('click', openSubmenu)

$('.js-showCatalogLinks').on('click', (event) => {
  event.preventDefault()

  const elem = $(event.currentTarget)
  const parent = elem.parents('.catalog-card__submenu')

  parent.find('.catalog-card__elem').addClass('is_show')

  elem.remove()
})
