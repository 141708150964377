const openDropdown = $('.js-openDropdown')

if (openDropdown.length > 0) {
  openDropdown.on('click', (event) => {
    event.stopPropagation()
    const elem = $(event.currentTarget)

    elem.toggleClass('is_active')
    elem.next().toggleClass('d-n')
  })

  $('body').on('click', () => {
    openDropdown.removeClass('is_active')
    openDropdown.next().addClass('d-n')
  })
}
