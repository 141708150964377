import Swiper, { Navigation, Thumbs } from 'swiper'

if ($('.js-sliderProductMain').length > 0) {
  const sliderProductThumbs = new Swiper('.js-sliderProductThumbs', {
    modules: [Navigation],
    direction: 'horizontal',
    slidesPerView: 4,
    spaceBetween: 12,
    speed: 200,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.js-sliderProductThumbsNext',
      prevEl: '.js-sliderProductThumbsPrev',
    },
    breakpoints: {
      1200: {
        slidesPerView: 5,
        direction: 'vertical',
      },
    },
  })

  new Swiper('.js-sliderProductMain', {
    modules: [Navigation, Thumbs],
    speed: 200,
    watchOverflow: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.js-sliderProductMainNext',
      prevEl: '.js-sliderProductMainPrev',
    },
    thumbs: {
      swiper: sliderProductThumbs,
    },

  })
}
