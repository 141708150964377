import Swiper, { Navigation, Pagination, EffectFade } from 'swiper'

if ($('.js-sliderMain').length > 0) {
  const sliderMain = new Swiper('.js-sliderMain', {
    modules: [Navigation, Pagination, EffectFade],
    speed: 200,
    effect: 'fade',
    pagination: {
      el: '.js-sliderMainPagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.js-sliderMainNext',
      prevEl: '.js-sliderMainPrev',
    },
  })

  sliderMain.on('slideChange', () => {
    const indexSlide = sliderMain.realIndex
    const mainSliderText = $('.js-mainSliderText')

    mainSliderText.removeClass('is_show')
    mainSliderText
      .eq(indexSlide)
      .addClass('is_show')
  })
}
