const fixedMobile = $('.js-fixedMobile')
const fixedMobileTriggerButton = $('.js-fixedMobileTriggerButton')
const fixedMobileButton = $('.js-fixedMobileButton')
const fixedMobileFinish = $('.js-fixedMobileFinish')

if (fixedMobile.length > 0 && window.innerWidth < 768) {
  $(window).on('load', () => {
    fixedMobile.addClass('is_load')
  })

  $(window).on('load resize scroll', () => {
    const scrollY = $(window).scrollTop()
    const triggerButtonY = fixedMobileTriggerButton.offset().top
    const vh = $(window).height()
    const finishY = fixedMobileFinish.offset().top

    if (scrollY + vh / 2 > triggerButtonY) {
      fixedMobileButton.addClass('d-n')
    } else {
      fixedMobileButton.removeClass('d-n')
    }

    if (scrollY + vh - 68 > finishY) {
      fixedMobile.addClass('d-n')
    } else {
      fixedMobile.removeClass('d-n')
    }
  })
}

$('.js-scrollToElem').on('click', (event) => {
  event.preventDefault()

  const elem = $(event.currentTarget)
  const id = elem.attr('href')

  if ($(id).length > 0) {
    $('html, body').animate({ scrollTop: $(id).offset().top - 40 }, 300)
  }
})
