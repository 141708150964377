$('.js-tabLink').on('click', (event) => {
  event.preventDefault()
  const elem = $(event.currentTarget)
  const tabContent = $(`${elem.attr('data-href')}`)

  elem.addClass('is_active').siblings().removeClass('is_active')
  tabContent.removeClass('d-n').siblings().addClass('d-n')
})

$('.js-tabSelect').on('change', (event) => {
  const elem = $(event.currentTarget)
  const value = elem.val()
  const tabContent = $(`${value}`)

  tabContent.removeClass('d-n').siblings().addClass('d-n')
})
