import Swiper, { Navigation, Pagination } from 'swiper'

if ($('.js-sliderMobileOnly').length > 0 && window.innerWidth < 768) {
  $('.js-sliderMobileOnly').each((index, elem) => {
    const sliderElem = $(elem)
    const hasPagination = sliderElem.find('.swiper-pagination').length > 0

    sliderElem.addClass(`js-sliderMobileOnly-${index}`)

    if (hasPagination) {
      sliderElem.find('.swiper-pagination').addClass(`js-sliderMobileOnlyPagination-${index}`)
    }

    const paginationConfig = {
      el: `.js-sliderMobileOnlyPagination-${index}`,
      clickable: true,
    }

    new Swiper(`.js-sliderMobileOnly-${index} .swiper`, {
      modules: [Navigation, Pagination],
      slidesPerView: 'auto',
      pagination: hasPagination ? paginationConfig : false,
    })
  })
}
