import svg4everybody from 'svg4everybody'
import $ from 'jquery'
import './vendor/imask'
import './vendor/pristine'
import './vendor/jquery.fancybox.min'
import './vendor/ion.rangeSlider'

svg4everybody()

window.$ = $
window.jQuery = $
